// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment: ApizConfig = {
  basehref: '/',
  configUrl: 'https://navailles.dev.apizsolutions.com/config-server/iupics-config/default/develop',
  production: true,
  appName: 'Apiz',
  CUST_version: '1.13.1-RC-2972aaac',
  STD_version: '2.10.1',
  default_language: 'fr_FR',
  webSiteUrl: 'https://www.apizsolutions.com/',
  available_languages: ['ar_TN', 'ca_ES', 'en_GB', 'en_US', 'en', 'es_ES', 'fr_FR', 'fr', 'nl_NL'],
  config: {
    backend: {
      auth: {
        url: 'https://navailles.dev.apizsolutions.com/auth-server',
        clientId: 'ws_api_001',
        clientSecret: 'ws_api_secret',
        paths: {
          connectas: '/connectas',
          login: '/session/token',
          session: '/session'
        }
      },
      socketcluster: {
        hostname: 'https://navailles.dev.apizsolutions.com/socketcluster',
        port: undefined,
        secure: true
      }
    }
  },
  constant: null,
  themes: null
};

export interface ApizConfig {
  production: boolean;
  config: IAppConfig;
  constant: { [key: string]: any };
  themes: { [key: string]: any };
  [key: string]: any;
}

export interface IAppConfig {
  backend: {
    auth?: {
      url: string;
      clientId: string;
      clientSecret: string;
      paths: { [key: string]: string };
    };
    docserver?: {
      url: string;
      [key: string]: any;
    };
    socketcluster?: {
      hostname: string;
      port: number | undefined;
      secure: boolean;
      [key: string]: any;
    };
    version?: {
      url: string;
      [key: string]: any;
    };
    ws?: {
      url: string;
      paths: { [key: string]: string };
      [key: string]: any;
    };
  };
}
